import React from 'react'
import {Link} from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import CoolHeader, {SectionHeader} from '../components/Elements/CoolHeader'

const Privacy = () => (
  <Layout>
    <SEO customTitle="Privacy Policy" />
    <CoolHeader>Privacy Policy</CoolHeader>
    <p>
      Pomme Journal is committed to protecting the privacy of subscribers and
      others who provide us with their personal information. This privacy policy
      was created so that you can learn what we do with your information. This
      policy pertains to information that is collected online and offline. By
      providing Pomme Journal with your personal information, you agree to be
      bound by the terms of this privacy policy. This policy may change from
      time to time, and we encourage you to review it periodically.
    </p>
    <p>
      Pomme Journal uses information it obtains from you in order to communicate
      with you about our activity, subscriptions and/or submissions to Pomme
      Journal (as applicable). We may also use information about the writers and
      artists whose work we publish to promote Pomme Journal. Each writer and
      artist whose work we publish agrees that Pomme Journal shall be permitted
      to use his or her name, biographical information and likeness to promote
      Pomme Journal and its activities.
    </p>
    <p>
      To help deliver Pomme Journal’s services, we sometimes use third party
      service providers and vendors (“Outside Contractors”). Pomme Journal may
      share your information with these Outside Contractors as necessary for
      those providers to provide the services in question. Access to your
      personally identifiable information by these Outside Contractors is
      limited to the information reasonably necessary in order for the Outside
      Contractors to perform their limited functions for us.
    </p>
    <p>
      Occasionally, the Pomme Journal website may allow you to click on links
      that will take you to third party websites. If you click on these links,
      please note that the Pomme Journal privacy policy does not apply to these
      third party websites, and we encourage you to read the privacy policy of
      the new sites you may visit. Finally, Pomme Journal may disclose your
      personally identifiable information to law enforcement officials and/or
      governmental authorities if we believe that we are required to do so by
      law.
    </p>
  </Layout>
)

export default Privacy
